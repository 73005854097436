.mapboxgl-ctrl-geocoder {
  width: 100% !important;
  max-width: none !important;
  box-shadow: none !important;

  &--icon,
  &--pin-right,
  &--powered-by {
    display: none !important;
  }

  &--input {
    @extend .field-input;
    padding-left: 12px;
    width: 100%;
  }

  .suggestions-wrapper {
    position: relative;
  }

  .suggestions {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
  }
}
