@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}

@mixin skeleton($height: auto, $width: auto) {
  @if $height == auto {
    height: $height;
  } @else {
    height: $height * 1px;
  }

  @if $width == auto {
    width: $width;
  } @else {
    width: $width * 1px;
  }

  background-image: linear-gradient(
    90deg,
    rgba(#fff, 0),
    rgba(#fff, 0.8),
    rgba(#fff, 0)
  );
  background-color: color(grey-6);
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;
}
