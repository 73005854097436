%field {
  &-base {
    padding: 8px 12px;
    height: 36px;
    border-radius: 6px;
    border: border(color(grey-6));
    background-color: color(white);
    transition: border-color 300ms ease;
  }

  &-hover {
    border-color: color(other-1);
  }

  &-focus {
    border-color: color(text-1);
  }

  &-error {
    border-color: color(other-3);
    color: color(other-3);
  }

  &-disabled {
    border-color: color(grey-6);
    color: color(grey-6);
    pointer-events: none;
  }
}

/**
 * Used for _shared/.mapboxgl-ctrl-geocoder
 **/
.field-input {
  @extend %field-base;
  @include font-size(14);
  color: color(text-1);
  outline: none;

  &::placeholder {
    color: color(grey-4);
  }

  &:hover {
    @extend %field-hover;
  }

  &:focus {
    @extend %field-focus;
  }

  &.error {
    @extend %field-error;
  }

  &.disabled {
    @extend %field-disabled;
  }
}

.field-textarea {
  @extend %field-base;
  height: auto;
  min-height: 64px;
  @include font-size(14);
  color: color(text-1);
  outline: none;
  resize: none;

  &::placeholder {
    color: color(grey-4);
  }

  &:hover {
    @extend %field-hover;
  }

  &:focus {
    @extend %field-focus;
  }

  &.error {
    @extend %field-error;
  }

  &.disabled {
    @extend %field-disabled;
  }

  &.resizable {
    resize: vertical;
  }
}

.field-select {
  &-container {
    display: grid;
    grid-template-areas: 'select';

    margin-bottom: 24px;
    width: 100%;
    height: 36px;

    @include border(color(grey-6));
    border-radius: 6px;
    background-color: color(white);

    @include transition(border-color);
    cursor: pointer;

    &.disabled {
      @extend %field-disabled;
    }

    &.error {
      @extend %field-error;
    }
  }

  &.disabled + &-chevron {
    color: color(primary-3);
  }
}

.field-switch {
  &-slider {
    display: block;
    position: relative;
    width: 40px;
    height: 24px;
    border-radius: 16px;
    background-color: color(grey-4);
    cursor: pointer;
    text-indent: -9999px;

    &.disabled {
      background-color: color(grey-6);
      cursor: not-allowed;
    }
  }

  &-slider::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    background-color: color(white);
    border-radius: 50%;
    @include transition();
  }

  &:checked + &-slider {
    background-color: color(primary-1);

    &.disabled {
      background-color: color(primary-2);
    }
  }

  &:checked + &-slider:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
}
