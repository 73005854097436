$font: 'Montserrat';

$space: 8;

/**
 * Lists
 */
$spacers: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;
$boxes: 16, 24, 32, 40, 48, 64;

/**
 * Spacing mappers
 */
$spacing-selectors: (
  'p': 'padding',
  'm': 'margin',
);
$spacing-properties: (
  't': 'top',
  'r': 'right',
  'b': 'bottom',
  'l': 'left',
);
$spacing-directions: 'x', 'y';

/**
 * UI variables
 */

/**
 * Sidebar is fixed, so it should be
 * padding for content
 */
$sidebar-width: 240px;
$sidebar-collapsed-width: 90px;

$layout-content-padding: 24px;

$transition-duration: 300ms;

//
// Colors
//

$color-selective: #ffb800;
$color-lace: #fff8e5;

$color-coral: #f24242;
$color-milano: #b40c0c;

$color-blackberry: #4e0224;
$color-jam: #9c0449;
$color-razzmatazz: #f50673;
$color-ruby: #d00561;
$color-pink: #ff00b8;
$color-candy: #fdb6d7;
$color-pig: #fedbeb;
$color-blush: #ffdff6;
$color-amour: #feedef;
$color-lavenderblush: #fef1f7;

$color-black: #000000;
$color-rustic: #1a010c;
$color-barossa: #3c2f35;
$color-rhino: #3e4954;
$color-grey: #7c7c7c;
$color-suva: #8c8c8c;
$color-silver: #c8c8c8;
$color-gainsboro: #dadada;
$color-whisper: #ebebeb;
$color-smoke: #f7f7f7;
$color-snow: #fafafa;
$color-alabaster: #fdfdfd;
$color-white: #ffffff;

$color-sapphire: #020a4e;
$color-violet: #9647fe;
$color-lavender: #cbd0fe;
$color-magnolia: #f2e8ff;

$color-seagreen: #1f8c4d;
$color-eucalyptus: #27ae60;
$color-apple: #bbecc8;
$color-latte: #daf5e1;
$color-bubbles: #eaf9ee;

$color-dodger: #18a0fb;
$color-anakiwa: #abdbff;
$color-onahau: #d1ebff;
$color-alice: #e8f5ff;

//
// Color system
//

$colors: (
  black: $color-black,
  white: $color-white,

  primary-1: $color-ruby,
  primary-2: $color-candy,
  primary-3: $color-pig,
  primary-4: $color-razzmatazz,
  primary-5: $color-jam,
  primary-6: $color-lavenderblush,

  grey-1: $color-rustic,
  grey-2: $color-barossa,
  grey-3: $color-grey,
  grey-4: $color-silver,
  grey-5: $color-gainsboro,
  grey-6: $color-whisper,
  grey-7: $color-smoke,
  grey-8: $color-snow,
  grey-9: $color-alabaster,

  text-1: $color-rhino,
  text-2: $color-suva,

  secondary-1: $color-apple,
  secondary-2: $color-latte,
  secondary-3: $color-bubbles,
  secondary-4: $color-sapphire,
  secondary-5: $color-anakiwa,
  secondary-6: $color-onahau,
  secondary-7: $color-alice,

  other-1: $color-lavender,
  other-2: $color-milano,
  other-3: $color-coral,
  other-4: $color-blackberry,
  other-5: $color-selective,
  other-6: $color-amour,
  other-7: $color-lace,
  other-8: $color-eucalyptus,
  other-9: $color-dodger,
  other-10: $color-seagreen,
  other-11: $color-magnolia,
  other-12: $color-violet,
  other-13: $color-pink,
  other-14: $color-blush,

  transparent: transparent,
);
