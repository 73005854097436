.billing-history-table {
  color: color(grey-1);

  th,
  td {
    @include font-size(14);
  }

  th {
    padding: 8px;
  }

  td {
    vertical-align: middle;
  }
}
