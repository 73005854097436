@keyframes spinner-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}

.chase-spinner {
  animation: spinner-chase 2.5s infinite linear both;
}

.chase-spinner div {
  animation: chase-dot 2s infinite ease-in-out both;
}

.chase-spinner div:before {
  animation: chase-dot-before 2s infinite ease-in-out both;
}

.chase-spinner :nth-child(1), .chase-spinner :nth-child(1):before {
  animation-delay: -1.1s;
}

.chase-spinner :nth-child(2), .chase-spinner :nth-child(2):before {
  animation-delay: -1.0s;
}

.chase-spinner :nth-child(3), .chase-spinner :nth-child(3):before {
  animation-delay: -0.9s;
}

.chase-spinner :nth-child(4), .chase-spinner :nth-child(4):before {
  animation-delay: -0.8s;
}

.chase-spinner :nth-child(5), .chase-spinner :nth-child(5):before {
  animation-delay: -0.7s;
}

.chase-spinner :nth-child(6), .chase-spinner :nth-child(6):before {
  animation-delay: -0.6s;
}
