.Toastify {
  flex: 0 1 auto !important;

  &__toast-container {
    max-width: 360px;
    width: 100%;
  }

  &__toast {
    padding: 16px 32px 16px 16px;
    @include font-size(16, 24);
    border-radius: 8px;

    &--success {
      @include border(color(other-8));
      background-color: color(secondary-3);
    }

    &--warning {
      @include border(color(other-5));
      background-color: color(other-7);
    }

    &--error {
      @include border(color(other-3));
      background-color: color(other-6);
    }

    &--info {
      @include border(color(other-9));
      background-color: color(secondary-7);
    }
  }

  &__toast-body {
    padding: 0;
  }

  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    @include font-size(20);
  }

  &__toast--success .close-button {
    color: color(other-8);
  }

  &__toast--warning .close-button {
    color: color(other-5);
  }

  &__toast--error .close-button {
    color: color(other-3);
  }

  &__toast--info .close-button {
    color: color(text-1);
  }

  .type-icon {
    @include font-size(24);
  }
}
