@import url('./chase-spinner.css');
@import url('./fade.css');
@import url('./terms-page.css');

@tailwind base;

@layer base {
  html {
    @apply text-text-1;
  }
}

@tailwind components;
@tailwind utilities;

.label-required::after {
  content: '*';
  display: inline-block;
  font-size: smaller;
  @apply inline-block align-middle leading-[1px] ml-[2px] text-other-3 h-0.5;
}

.truncate-2 {
  @supports (-webkit-line-clamp: 2) {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  @supports not (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
  }
}

.content-center {
  @apply items-center justify-center;
}

.leading-normal {
  line-height: normal;
}

.primary-1-with-hover {
  @apply text-primary-1 hover:text-primary-5;
}

.grey-3-with-hover {
  @apply text-grey-3 hover:text-grey-2;
}

.other-3-with-hover {
  @apply text-other-3 hover:text-other-2;
}
